const people = {
    nalinranasinghe: {
        name: "Nalin Ranasinghe",
        namewithhonorifics: "Dr. Nalin Ranasinghe",
        position: "Senior Lecturer | University of Colombo",
        link: "https://ucsc.cmb.ac.lk/profile/dnr/",
        web: "https://ucsc.cmb.ac.lk/profile/dnr/",
        linkedin: undefined,
        scholar: "https://scholar.google.com/citations?user=MewGh0QAAAAJ&hl=en",
        researchgate: undefined,
    },
    gihanseneviratne: {
        name: "Gihan Seneviratne",
        namewithhonorifics: "Dr. Gihan Seneviratne",
        position: "Senior Lecturer | University of Colombo",
        link: "https://ucsc.cmb.ac.lk/profile/gps/",
        web: "https://ucsc.cmb.ac.lk/profile/gps/",
        linkedin: undefined,
        scholar: "https://scholar.google.com/citations?user=vl3S1uQAAAAJ&hl=en",
        researchgate: undefined,
    },
    noelfernando: {
        name: "Noel Fernando",
        namewithhonorifics: "Dr. Noel Fernando",
        position: "Senior Lecturer | University of Colombo",
        link: "https://ucsc.cmb.ac.lk/profile/nas/",
        web: "https://ucsc.cmb.ac.lk/profile/nas/",
        linkedin: undefined,
        scholar: undefined,
        researchgate: "https://www.researchgate.net/profile/Noel-Fernando",
    },
    mohammadnabeel: {
        name: "Mohammad Nabeel",
        namewithhonorifics: "Dr. Mohammad Nabeel",
        position: "Research Scientist | Qatar Computing Research Institute (QCRI)",
        link: "https://www.hbku.edu.qa/en/staff/dr-mohamed-nabeel",
        web: "https://www.hbku.edu.qa/en/staff/dr-mohamed-nabeel",
        linkedin: "https://scholar.google.com/citations?user=Lka4RwsAAAAJ&hl=en",
        scholar: undefined,
        researchgate: undefined,
    },
    leoniesolomons: {
        name: "Leonie Solomons",
        namewithhonorifics: "Dr. Leonie Solomons",
        position: "Executive Director at Consulting Systems Pty Ltd, Language Matters",
        link: "https://www.linkedin.com/in/leonie-solomons-2aa5b31a/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/leonie-solomons-2aa5b31a/",
        scholar: undefined,
        researchgate: undefined,
    },
    disnirathnayake: {
        name: "Disni Rathnayake",
        namewithhonorifics: "Ms. Disni Rathnayake",
        position: "Research Assistant | University of Colombo",
        link: "https://www.linkedin.com/in/disni-rathnayake-3178b210b/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/disni-rathnayake-3178b210b/",
        scholar: undefined,
        researchgate: undefined,
    },
    amilaindika: {
        name: "Amila Indika",
        namewithhonorifics: "Mr. Amila Indika",
        position: "Lecturer (On contract) | University of Peradeniya",
        link: "https://www.linkedin.com/in/amila-indika/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/amila-indika/",
        scholar: undefined,
        researchgate: undefined,
    },
    sumedhedissanayake: {
        name: "Sumedhe Dissanayake",
        namewithhonorifics: "Mr. Sumedhe Dissanayake",
        position: "Senior Software Engineer | WSO2",
        link: "https://www.linkedin.com/in/sumedhe/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/sumedhe/",
        scholar: undefined,
        researchgate: undefined,
    },
    harindasamarasekara: {
        name: "Harinda Samarasekara",
        namewithhonorifics: "Mr. Harinda Samarasekara",
        position: "Senior Software Engineer | Yaala Labs",
        link: "https://www.linkedin.com/in/harinda05/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/harinda05/",
        scholar: "https://scholar.google.com/citations?user=jILeczkAAAAJ&hl=en",
        researchgate: "https://www.researchgate.net/scientific-contributions/Harinda-Samarasekara-2191573543",
    },
    indrajithekanayake: {
        name: "Indrajith Ekanayake",
        namewithhonorifics: "Mr. Indrajith Ekanayake",
        position: "Visiting Lecturer | Open University of Sri Lanka",
        link: "https://www.linkedin.com/in/indrajithek/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/indrajithek/",
        scholar: undefined,
        researchgate: undefined,
    },
    dinalherath: {
        name: "Dinal Herath",
        namewithhonorifics: "Dr. Dinal Herath",
        position: "Security Data Scientist | Obsidian Security, USA",
        link: "https://www.dinalherath.com/",
        web: "https://www.dinalherath.com/",
        linkedin: "https://www.linkedin.com/in/jerome-dinal-herath/",
        scholar: "https://scholar.google.com/citations?user=vNtiUMwAAAAJ&hl=en",
        researchgate: "https://www.researchgate.net/profile/Jerome-Dinal-Herath-2",
    },
    dinunifernando: {
        name: "Dinuni Fernando",
        namewithhonorifics: "Dr. Dinuni Fernando",
        position: "Lecturer | University of Colombo",
        link: "https://dinuni.github.io/",
        web: "https://dinuni.github.io/",
        linkedin: "https://www.linkedin.com/in/dinunifernando/?originalSubdomain=lk",
        scholar: "https://scholar.google.com/citations?user=ViZRk00AAAAJ&hl=en",
        researchgate: "https://www.researchgate.net/profile/Dinuni-Fernando",
    },
    upuljayasinghe: {
        name: "Upul Jayasinghe",
        namewithhonorifics: "Dr. Upul Jayasinghe",
        position: "Lecturer | University of Peradeniya",
        link: "https://www.linkedin.com/in/upuljm/",
        web: undefined,
        linkedin: "https://www.linkedin.com/in/upuljm/",
        scholar: "https://scholar.google.com/citations?user=9nBn4b0AAAAJ",
        researchgate: "https://www.researchgate.net/profile/Upul-Jayasinghe",
    },
}

export default people
